<template>
    <!-- 单纯用来给聊天室代码划分区域的 -->
    <div class="scrollbar-demo-item">
        <slot></slot>
    </div>
</template>

<script>
</script>

<style scoped>

</style>
