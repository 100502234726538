<template>
    <iframe src="/games/mota/index.html" frameborder="0" style="height:800px;width:100%"></iframe>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
<style scoped>

</style>
