<template>
    <ContentField>
        <div class="row justify-content-md-center">
            <div class="col-3">
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="mb-3">
                        <label for="confirmedPassword" class="form-label">确认密码</label>
                        <input v-model="confirmedPassword" type="password" class="form-control" id="confirmedPassword" placeholder="请再次输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">提交</button>
                </form>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../../components/ContentField.vue'
import { ref } from 'vue'
import router from '../../../router/index'
import $ from 'jquery'

export default {
    components: {
        ContentField
    },
    setup() {
        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let error_message = ref('');
        let photo = ref('');

        const register = () => {
            //先给photo这个变量赋值，内容为头像的地址
            // getAvatar();
            $.ajax({
                url: "https://game.haruka.website/api/user/account/register/",
                type: "post",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value,
                    photo:photo.value,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        router.push({name: "user_account_login"});
                    } else {
                        error_message.value = resp.error_message;
                    }
                },
            });
        }
        //获取随机头像,暂时先不用，json好像处理不好，
        const getAvatar = () =>{
            // $.ajax({
            //   url:"https://api.vvhan.com/api/avatar/dm?type=json",
            //   type:"get",
            //   dataType:"json",
            //   success(resp){
            //     // let msg = JSON.parse(resp.url);
            //     photo.value = resp.url;
            //   },
            //   error(){
            //     console.log("随机头像请求失败");
            //     photo.value = "";
            //   }
            // })
        }

        return {
            username,
            password,
            confirmedPassword,
            error_message,
            register,
            getAvatar,
        }
    }
}
</script>

<style scoped>
button {
    width: 100%;
}

div.error-message {
    color: red;
}
</style>